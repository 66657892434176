import moment from "moment";
import React from "react";
import { Card } from "react-bootstrap";
import ReactDOM from "react-dom";
import { VideoMarker } from "../../types";
import IncidentMap from "./incident-map";
import { ppicApiService } from "../../services/ppic-api-service";

interface Props {
  incident: any;
  videos: any[];
  activeVideo?: any;
  activeVideoIndex?: any;
  isToken?: boolean;
}

interface State {
  incident: any;
  videos: any[];
  activeVideo: any;
  activeVideoIndex: any;
  users: any;
}

export default class VideoContainer extends React.Component<Props, State> {
  deviceMarkerIcons = {
    latest:
      "https://s3.amazonaws.com/assets.incidentcode.com/marker-video-latest.png",
    active:
      "https://s3.amazonaws.com/assets.incidentcode.com/marker-video-active.png",
    default:
      "https://s3.amazonaws.com/assets.incidentcode.com/marker-video-default.png",
    user:
      "https://s3.amazonaws.com/assets.incidentcode.com/new-map-image.png",
  };
  pollInterval = 8000;
  pollTokenTimeout: any = 0;

  constructor(props: Props) {
    super(props);

    this.state = {
      incident: props.incident,
      videos: props.videos.filter((v) => {
        return v.videoURL !== "https://s3.amazonaws.com//";
      }),
      activeVideo: props.videos.length > 0 ? props.videos[0] : null, // if we have videos, set the first one as active
      activeVideoIndex: 0,
      users: [],
    };
  }

  componentWillReceiveProps(nextProps: Props) {
    // clear it all out
    if (nextProps.videos.length == 0) {
      return this.setState({
        videos: [],
        activeVideo: null,
        activeVideoIndex: 0,
      });
    }

    // only rest if the first video (most recent has changed)
    var trueVideos = nextProps.videos.filter(
      (v) => v.videoURL !== "https://s3.amazonaws.com//"
    );
    if (trueVideos.length) {
      var prevVideos = this.props.videos.filter((v) => {
        return v.videoURL !== "https://s3.amazonaws.com//";
      })
      var nextFirstVideoId = trueVideos[0] && trueVideos[0].id;
      var currFirstVideoId = prevVideos && prevVideos[0] && prevVideos[0].id;

      if (nextFirstVideoId != currFirstVideoId) {
        // if (nextProps.videos.length !== this.state.videos.length) {
        this.setState({
          videos: trueVideos,
          activeVideo: trueVideos[0],
          activeVideoIndex: 0,
        });
      } else {
        // old video, move
        this.setState({
          videos: trueVideos,
        });
      }
    }
  }

  handleMarkerClick = (index: number) => {
    this.setState({
      activeVideo: this.state.videos[index],
      activeVideoIndex: index,
    });
  };

  handlePageChange(direction: string) {
    var len = this.state.videos.length;
    var index = this.state.activeVideoIndex;

    if (direction == "next") {
      index = index + 1 >= len ? 0 : index + 1; // if we are the end, loop back to beginning
    } else {
      index = index == 0 ? len - 1 : index - 1;
    }

    this.setState({
      activeVideo: this.state.videos[index],
      activeVideoIndex: index,
    });
  }

  componentDidUpdate(prevProps: Props, prevState: Props) {
    if (
      prevState.activeVideo &&
      prevState.activeVideo.videoURL != this.state.activeVideo.videoURL &&
      this.refs.video
    ) {
      (ReactDOM.findDOMNode(this.refs.video) as HTMLVideoElement).load();
    }
  }

  componentDidMount() {
    ppicApiService.getUsersRelatedToLocation(`${this.props.incident.id}`).then((res:any) => {
      this.setState({users: res})
    })
    this.pollTokenTimeout = setInterval(() => {
      ppicApiService.getUsersRelatedToLocation(`${this.props.incident.id}`).then((res:any) => {
        this.setState({users: res})
      })
    }, this.pollInterval)
    
    // ppicApiService.getUsersRelatedToLocation("5697017").then((res:any) => {
    //   console.log("res---->", res)
    //   this.setState({users: res})
    // })
  }

  componentWillUnmount() {
    clearInterval(this.pollTokenTimeout);
  }
  /**
   * renders the video container and pagination
   */
  renderVideo() {
    var { videos, activeVideo, activeVideoIndex } = this.state;
    // {console.log(this.state.videos)}
    // {console.log(activeVideoIndex)}
    if (!videos.length) {
      return (
        <div className="thumbnail">
          <div className="caption">
            <h3>
              <i className="fa fa-fw fa-video-camera"></i> No videos at this
              time{" "}
            </h3>
          </div>
        </div>
      );
    }

    if (!activeVideo) {
      return (
        <div className="thumbnail">
          <div className="caption">
            <h3>
              <i className="fa fa-fw fa-video-camera"></i> Videos{" "}
              <small>
                <i className="fa fa-hourglass"></i> Waiting for videos...
              </small>
            </h3>
          </div>
        </div>
      );
    }

    var videosLength = videos.length;
    var paginateLeftActive = activeVideoIndex > 0;
    var paginateRightActive =
      videosLength > 1 && activeVideoIndex != videosLength - 1;
    var time = moment(activeVideo.createdAt).format("LLL");
    var re = /(?:\.([^.]+))?$/;
    const extension = re.exec(activeVideo.videoURL)[1];

    return (
      <Card>
        <Card.Body>
          <div className="embed-responsive embed-responsive-4by3">
            {
              extension === "mp4" ?
              <video preload="auto" ref="video" controls autoPlay loop>
                <source src={activeVideo.videoURL} type="video/mp4" />
              </video> : 
              extension !== undefined ?
              <img className="embed-responsive-item embed-responsive embed iframe object video" src={activeVideo.videoURL} alt="this slowpoke moves"/> :
              <></>
            }
          </div>

          <div className="row mt-1 mb-1">
            <div className="col-6">
              <i className="fa fa-clock-o"></i> {time}
            </div>
            <div className="col-6 text-right">
              { activeVideo.deviceName ? <><i className="fa fa-video-camera"></i> {activeVideo.deviceName} </>: <></>}
            </div>
          </div>
          <div className="row">
            <div className="col-5">
              <button
                type="button"
                className={
                  "btn btn-block btn-play " +
                  (!paginateLeftActive ? "btn-primary" : "btn-success")
                }
                onClick={this.handlePageChange.bind(this, "prev")}
              >
                <i className="fa fa-chevron-circle-left fa-fw"></i> Prev Media
              </button>
            </div>
            <div className="col-2 text-center">
              {activeVideoIndex + 1} of {videos.length}
            </div>
            <div className="col-5">
              <button
                type="button"
                className={
                  "btn btn-block btn-play " +
                  (!paginateRightActive ? "btn-primary" : "btn-success")
                }
                onClick={this.handlePageChange.bind(this, "next")}
              >
                Next Media <i className="fa fa-chevron-circle-right fa-fw"></i>
              </button>
            </div>
          </div>
        </Card.Body>
      </Card>
    );
  }

  renderMap() {
    var { incident, videos, activeVideoIndex } = this.state;

    var markers: VideoMarker[] = [];

    if (videos.length > 0) {
      markers = videos.map((video, index) => {
        var icon = this.deviceMarkerIcons.default;
        if (index == 0) {
          icon = this.deviceMarkerIcons.latest;
        } else if (activeVideoIndex == index) {
          icon = this.deviceMarkerIcons.active;
        }

        return {
          title: "#" + (index + 1),
          icon: icon,
          onClick: this.handleMarkerClick.bind(this, index),
          zIndex: 9000 - index,
          position: {
            lat: video.deviceLat || incident.locationLat,
            lng: video.deviceLng || incident.locationLng,
          },
        };
      });
    }

    if (this.state.incident.is_geo_creds) {
      // console.log("this.state.users", this.state.users)
      this.state.users.map((user:any) => {
        let a: VideoMarker = {
          title: `${user.username} ${user.mobile} `,
          zIndex: 9999,
          position: {
            lat: Number(user.latitude),
            lng: Number(user.longitude)
          },
          onClick: function(){},
          isUser: true
        }
        if (!this.props.isToken) {
          a.title = ""
        }
        markers.push(a)
      })
    }

    return (
      <div>
        <IncidentMap incident={incident} markers={markers} />
      </div>
    );
  }

  render() {
    var { incident } = this.state;
    return (
      <div className="row">
        <div className="col-sm-12 col-lg-6">{this.renderVideo()}</div>
        <div className="col-sm-12 col-lg-6">
          <Card>
            <Card.Body>
              {this.renderMap()}
              <ul
                className="list-inline"
                style={{ paddingTop: "1rem", fontSize: "13px" }}
              >
                <li className="list-inline-item">
                  <img
                    src={this.deviceMarkerIcons.latest}
                    alt=""
                    style={{ height: "24px", width: "24px" }}
                  />{" "}
                  Most Recent Video &nbsp;{" "}
                </li>
                <li className="list-inline-item">
                  <img
                    src={this.deviceMarkerIcons.active}
                    alt=""
                    style={{ height: "24px", width: "24px" }}
                  />{" "}
                  Active Video
                </li>
                <li className="list-inline-item">
                  <img
                    src={this.deviceMarkerIcons.default}
                    alt=""
                    style={{ height: "24px", width: "24px" }}
                  />{" "}
                  Past Video
                </li>
                <li className="list-inline-item">
                  <img
                    src={this.deviceMarkerIcons.user}
                    alt=""
                    style={{ height: "24px", width: "16px" }}
                  />{" "}
                  User
                </li>
              </ul>

              <div>
                <h4 style={{ marginTop: "1rem" }}>
                  <strong>{incident.locationName}</strong>
                </h4>
                <h5>
                  <i className="fa fa-map-marker"></i>{" "}
                  {incident.locationAddress}
                </h5>
              </div>
            </Card.Body>
          </Card>
        </div>
      </div>
    );
  }
}
